@charset "UTF-8";

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/roboto-light.eot");
  src: local(※), url("../fonts/roboto-light.woff") format('woff')
}

/* E 확장자앞 컴마 수정 */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto-regular.eot");
  src: local(※), url("../fonts/roboto-regular.woff") format('woff')
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/roboto-medium.eot");
  src: local(※), url("../fonts/roboto-medium.woff") format('woff')
}

/* reset */
html, body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, textarea, p, blockquote, th, td, input, select, textarea, button {
  margin: 0;
  padding: 0
}

p {
  padding: 0;
  margin: 0;
}

dl, ul, ol, menu, li {
  list-style: none
}

input, select, button {
  vertical-align: middle
}

input:focus, select:focus, button:focus, textarea:focus {
  outline: none !important;
  outline-offset: 0 !important;
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

img {
  border: none;
  vertical-align: top
}

i, em, address {
  font-style: normal
}

a:hover, a:active, a:link, a:visited {
  text-decoration: none
}

a:focus {
  outline: none;
}

legend, caption {
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  font-size: 0;
  line-height: 0;
  white-space: nowrap
}

/* layout */
html {
  background: #e1e5e6 url(../images/m_bg_container.png) no-repeat 50% 100%;
  background-size: 100% 100%;
  background-attachment: fixed
}

html.s-android {
  background: none;
}

html.s-android .s-page-bg {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #e1e5e6 url(../images/m_bg_container.png) no-repeat 50% 100%;
  background-size: 100% 100%;
  transform: translate3d(0, 0, 0)
}

body {
  background-color: transparent;
}

.s-wrap {
  box-sizing: border-box;
  height: 100%;
  padding-top: 2.6875em;
}

.s-wrap.s-is-navi {
  padding-top: 4.8125em;
}

.s-wrap.s-is-nonheader {
  padding-top: 0;
}

.s-wrap.s-is-nonheader .s-header {
  display: none;
}

.s-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}

.s-container {
  position: relative;
  box-sizing: border-box;
  min-height: 100%;
}

/* grid */
.s-xs-offset0 {
  padding: 0;
}

/* sp */
.s-btn-quickmenu span, .s-des-linkitem .s-des-arrow span, .s-checkbox .s-icon-chk, .s-checkbox.s-on .s-icon-chk, .s-icon-help, .s-sub-header .s-btn-back span, .s-des-linkitem .s-item-icon, .s-img-panel li .s-panel-arrow span, .s-img-panel li .s-panel-arrow span.s-dim-arrow, .s-icon-caution, .slick-dots button, .s-log .s-btn-log span, .dropdown .s-dropdown-arrow, .s-sorting .dropdown-menu .s-current a:after, .remodal-slider-wrap .slick-arrow, .s-text-panel .s-panel-arrow span, .s-error-section:before, .s-select label span:after, .s-btn-benefitinfo {
  background-image: url(../images/sp.png);
  background-repeat: no-repeat;
  background-size: 100px 120px;
}

.s-card-number {
  height: 100%;
}

.s-card-number span {
  display: inline-block;
  overflow: hidden;
  width: 19%;
  height: 100%;
  background: no-repeat 0 0;
  background-size: 100% 100%;
  text-indent: -9999px;
}

.s-card-number .s-cn1 {
  background-image: url(../images/number_card_1.png)
}

.s-card-number .s-cn2 {
  background-image: url(../images/number_card_2.png)
}

.s-card-number .s-cn3 {
  background-image: url(../images/number_card_3.png)
}

.s-card-number .s-cn4 {
  background-image: url(../images/number_card_4.png)
}

.s-card-number .s-cn5 {
  background-image: url(../images/number_card_5.png)
}

.s-card-number .s-cn6 {
  background-image: url(../images/number_card_6.png)
}

.s-card-number .s-cn7 {
  background-image: url(../images/number_card_7.png)
}

.s-card-number .s-cn8 {
  background-image: url(../images/number_card_8.png)
}

.s-card-number .s-cn9 {
  background-image: url(../images/number_card_9.png)
}

.s-card-number .s-cn0 {
  background-image: url(../images/number_card_0.png)
}

.s-card-number .s-cns {
  background-image: url(../images/number_card_s.png)
}

/* section */
.s-section {
  padding-top: 1.25em;
}

.s-topline-section {
  padding: 0.9375em 0;
  background: url(../images/bg_section.png) no-repeat 50% 0;
  background-size: 100% 2px;
}

.s-section + .s-topline-section {
  margin-top: 0.75em;
}

/* text */
.s-container-titlewrap {
  padding: 1.375em 35px 1.125em;
  text-align: center;
}

.s-container-title {
  font-size: 0.875em;
  font-weight: 400;
  color: #555;
  line-height: 150%;
}

.s-section-title {
  margin: 0;
  font-size: 0.875em;
  font-weight: 400;
  color: #111;
}

.s-info-group .s-info-title {
  font-size: 0.875em;
  font-weight: 400;
  color: #555;
  line-height: 150%;
}

.s-info-group .s-info-big-title {
  font-size: 1em;
  font-weight: 400;
  color: #555;
  line-height: 150%;
}

.s-info-group .s-info-cont {
  font-size: 0.6875em;
  color: rgba(85, 85, 85, 0.7);
  line-height: 150%;
}

.s-contactus {
  margin-top: 0.8125em;
  text-align: center;
}

.s-contactus p {
  margin-top: 10px;
  font-size: 0.625em;
  color: #555;
  color: rgba(85, 85, 85, 0.7);
  line-height: 130%;
}

.s-contactus p:first-child {
  margin-top: 0;
}

.s-contactus a {
  font-size: 1em;
  font-weight: 500;
  color: #111;
  color: rgba(17, 17, 17, 0.7);
  line-height: 110%;
  text-decoration: underline
}

.s-legal {
  margin-top: 0.4375em;
  text-align: center;
}

.s-legal p {
  font-size: 0.625em;
  color: #555;
  color: rgba(85, 85, 85, 0.7);
  line-height: 150%;
}

.s-legal a {
  color: #555;
  color: rgba(85, 85, 85, 0.7);
}

.s-tabtitle {
  display: block;
  position: relative;
  padding: 0.5385em 12px;
  font-size: 0.8125em;
  font-weight: 400;
  color: #111;
  line-height: 1.53846;
}

.s-tabtitle:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
  height: 10px;
  background: url(../images/bg_shadow_bottom.png) no-repeat 50% 100%;
  background-size: 100% 10px;
}

/* img */
.s-mimg {
  display: block;
}

.s-pimg {
  display: none;
}

/* align */
.s-type-center {
  text-align: center;
}

.s-type-left {
  text-align: left;
}

.s-type-right {
  text-align: right
}

/* display */
.s-display-inline {
  display: inline-block
}

/* width */
.s-width-65 {
  width: 65%
}

/* font-weight */
.s-font-weight-900 {
  font-weight: 900;
}

/* grey */
.s-font-color {
  color: #555;
}

/* hide */
.s-hide {
  display: none;
}

/* btn */
.s-btn {
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  -webkit-appearance: none;
  vertical-align: middle;
}

.s-btn-default {
  display: inline-block;
  width: 100%;
  height: 2.8em;
  border: 1px solid #fff;
  border-radius: 2px;
  background: #fff;
  font-size: 0.9375em;
  box-shadow: 0 1px 1px rgba(79, 69, 39, 0.3);
  color: #555;
  line-height: 2.667em;
  text-align: center;
  vertical-align: middle;
}

.zipcode {
  padding: 0em 0em 2em 0em;
}

.zipcode-font {
  font-size: 0.8125em;
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

.s-btn-default.s-btn-disable {
  background: #fdfdfd;
  background: rgba(255, 255, 255, 0.9);
  color: #999;
  color: rgba(85, 85, 85, 0.4);
}

.s-col-btnwrap:after {
  content: "";
  display: block;
  clear: both;
}

.s-col-btnwrap .s-btn-item {
  box-sizing: border-box;
  float: left;
  width: 50%;
  padding-left: 0.15625em;
}

.s-col-btnwrap .s-btn-item.btn-long {
  width: 100%;
}

.s-col-btnwrap .s-btn-item:first-child {
  padding: 0 0.15625em 0 0;
}

.form-group + .s-btn {
  margin-top: 8px;
}

.one_btn_wrapping {
  margin: 1.75em auto 0;
  width: 74.2%;
  text-align: center;
}

.one_btn_wrapping.formM, .one_btn_wrapping.formM_t20 {
  margin-top: 0.5em
}

.one_btn_wrapping .s-btn-default {
  max-width: 250px;
}

.s-info-box + .one_btn_wrapping {
  margin-top: 3.125em;
}

.form-group > .s-btn-default {
  margin-top: 0.5em;
}

/* icon */
.s-icon-help, .s-icon-paypal, .s-icon-card, .s-icon-code, .s-icon-caution {
  display: block;
  overflow: hidden;
  text-indent: -9999px;
}

.s-icon-help {
  width: 16px;
  height: 16px;
  background-position: -15px -20px;
}

.s-icon-paypal {
  width: 20px;
  height: 24px;
  background-position: -55px 0;
}

.s-icon-card {
  width: 23px;
  height: 24px;
  background-position: -55px -26px;
}

.s-icon-code {
  width: 23px;
  height: 24px;
  background-position: -55px -52px;
}

.s-icon-caution {
  width: 22px;
  height: 18px;
  background-position: -33px -20px;
}

/* form */
.s-form-title {
  font-size: 0.875em;
  font-weight: 400;
  color: #555;
  line-height: 1.42857;
}

.form-group .s-input, .form-group .s-select, .form-group .s-textarea-box, .form-group .s-radio-tabwrap {
  margin-top: 0.5em;
}

.form-group .s-select + .s-textarea-box {
  margin-top: 0.3125em;
}

.form-group .s-input:first-child, .form-group .s-select:first-child, .form-group .s-textarea-box:first-child, .form-group .s-radio-tabwrap:first-child {
  margin-top: 0;
}

.form-group .s-form-title + .s-input-inlinegroup .s-input, .form-group .s-form-title + .s-input {
  margin-top: 0.1875em;
}

.s-form-groupwrap {
  margin-top: 0.9375em
}

.s-form-groupwrap .form-group {
  margin-top: 0.3125em;
}

.s-input {
  display: block;
  overflow: hidden;
  position: relative;
}

.s-input label {
  display: block;
  position: absolute;
  left: 12px;
  top: 0.6154em;
  height: 2.154em;
  margin-bottom: 0;
  font-size: 0.8125em;
  font-weight: 400;
  color: #999;
  color: rgba(85, 85, 85, 0.5);
  line-height: 2.154em;
  vertical-align: middle;
  cursor: text;
}

.s-input input {
  display: block;
  width: 100%;
  height: 3.077em;
  padding: 0.6923em 12px 0.615385em;
  border: 1px solid #e7ebec;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 3px;
  background: #ccd9dd;
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.25);
  font-size: 0.8125em;
  color: #555;
  vertical-align: baseline;
  -webkit-appearance: none;
}

.s-input input::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.s-input input:-ms-input-placeholder {
  color: #999;
}

.s-input input::-webkit-input-placeholder {
  color: #999;
}

.s-input input:disabled {
  color: #555;
  color: rgba(85, 85, 85, 0.5);
}

.s-input input::-ms-clear {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}

/* close x */
input.s-cleanable {
  background: #ccd9dd url(../images/icon_clear_x1.png) no-repeat right -15px center;
  background-size: 12px 12px;
}

.s_error_input input.s-cleanable {
  color: #f86900;
  background: #eedbcb url(../images/icon_r_clear_x1.png) no-repeat right -15px center;
  background-size: 12px 12px;
}

input.s-cleanable.x {
  background-position: right 18px center;
}

input.s-cleanable.onX {
  cursor: pointer;
}

input.s-cleanable::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.s-input-inlinegroup:after {
  content: "";
  display: block;
  clear: both;
}

.s-input-inlinegroup .s-input {
  float: left;
  box-sizing: border-box;
  width: 50%;
  padding: 0 4px;
}

.s-input-inlinegroup .s-input:first-child {
  padding-left: 0;
}

.s-input-inlinegroup .s-input label {
  left: 16px;
}

.s-input-inlinegroup .s-input:first-child label {
  left: 12px;
}

.s-checkbox {
  display: block;
  position: relative;
  overflow: hidden;
  padding-left: 23px;
}

.s-checkbox input {
  width:15px;
  height: 15px;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid black;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-top: -4px;
  margin-right: 5px;
}

.s-checkbox input:checked {
  appearance: auto;
  clip-path: circle(50% at 50% 50%);
  background-color: rgb(28, 116, 167);
}

.s-checkbox label {
  margin-top: 8px;
  cursor: pointer;
  font-weight: inherit;
}

.s-checkbox label.blueText {
  color: rgb(28, 116, 167);
  font-weight: inherit;
}

.s-checkbox .s-icon-chk {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  background-position: -14px 0;
}

.s-checkbox.s-on .s-icon-chk {
  width: 19px;
  background-position: -31px 0;
}

.s-radio-tabwrap {
  overflow: hidden;
}

.s-radio-tabwrap .s-radio-tab {
  position: relative;
  float: left;
  overflow: hidden;
  width: 50%;
}

.s-radio-tabwrap .s-radio-tab input[type="radio"] {
  position: absolute;
  left: -999px;
  top: 0;
}

.s-radio-tabwrap .s-radio-tab label {
  width: 100%;
  padding: 0.4286em 0;
  margin: 0;
  background: #d5e0e3;
  background: rgba(0, 0, 0, 0.08);
  font-size: 0.875em;
  font-weight: 400;
  color: #555;
  color: rgba(85, 85, 85, 0.8);
  line-height: 200%;
  text-align: center;
  cursor: pointer;
}

.s-radio-tabwrap .s-radio-tab input[type="radio"]:checked + label {
  background: #fff;
  color: rgba(17, 17, 17, 0.8);
}

.s-select {
  position: relative;
  height: 2.5em;
}

.s-select label {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 3.077em;
  padding: 0.538em 12px 0.385em;
  border: 1px solid #fff;
  border-radius: 2px;
  background: #fff;
  box-shadow: 0 1px 1px rgba(79, 69, 39, 0.3);
  font-size: 0.8125em;
  font-weight: normal;
  color: #555;
  line-height: 2.154;
  text-align: left;
  vertical-align: middle;
  cursor: pointer;
}

.s-select label span {
  position: absolute;
  right: 0;
  top: 0.15em;
  width: 2.769231em;
  height: 2.769231em;
  border-left: 1px solid #e4e3df;
  text-indent: -9999px;
}

.s-select label span:after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 6px;
  height: 4px;
  margin: -2px 0 0 -3px;
  background-position: -41px -79px;
}

.s-select select {
  width: 100%;
  height: 100%;
  padding: 0 12px;
  border: 0;
  font-family: inherit;
  line-height: normal;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.s-select ul {
  display: none;
  position: absolute;
  left: 0;
  top: 2.5625em;
  width: 100%;
  border-radius: 2px;
  box-shadow: 0px 10px 15px rgba(79, 69, 39, 0.3);
  background: #f2f4f5;
}

.s-select ul li {
  background: url(../images/bg_select_option.png) no-repeat 50% 0;
  background-size: 100% 2px
}

.s-select ul li:first-child {
  background: none;
}

.s-select ul button {
  display: block;
  width: 100%;
  height: 3.1666667em;
  padding: 0 12px;
  border: none;
  background: none;
  font-size: 0.75em;
  text-align: left;
}

.s-select.s-active label {
  background: #f2f4f5;
  color: #111;
}

.s-select.s-active label span:after {
  background-position: -49px -79px;
}

.s-select button:focus {
  outline: none;
}

.s-textarea-box {
  position: relative;
}

.s-textarea-box .s-disabledinfo {
  position: absolute;
  left: 12px;
  top: 0.76923em;
  font-size: 0.8125em;
  color: rgba(85, 85, 85, 0.5);
}

.s-textarea-box textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 16em;
  padding: 0.738em 12px;
  border: 1px solid #e7ebec;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 3px;
  background: #ccd9dd;
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.25);
  font-size: 0.8125em;
  color: #555;
  line-height: 1.42857143;
  -webkit-appearance: none;
}

.s-textarea-box .s-disabledinfo + textarea {
  padding-top: 2.24em
}

.form-group.s-is-date .s-input-inlinegroup .s-input, .form-group.s-is-citystate .s-input-inlinegroup .s-input {
  width: 35%;
}

/* form text */
.s-form-necessary {
  color: #208bbd;
}

.s-form-notice {
  display: block;
  margin-top: 0.3em;
  font-size: 0.625em;
  color: rgba(85, 85, 85, 0.7);
  letter-spacing: -0.06em;
}

/* form error */
.s_error_input input {
  color: #f86900;
  background: #eedbcb;
  background: rgba(248, 105, 0, 0.15)
}

.s_error_msg {
  display: block;
  color: #f86900;
  font-size: 0.6875em;
  line-height: 100%;
  margin-top: 0.545em;
}

/* form dim */
.s-dimcase-input .s-dimcase {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0.538em 12px 0.385em;
  border-radius: 3px;
  background: #ccd9dd;
  font-size: 0.8125em;
  color: #555;
  color: rgba(85, 85, 85, 0.2);
  line-height: 2em;
  vertical-align: middle;
}

.selectLanguage {
  display: inline-block;
  vertical-align: middle;
  margin-left: 3px;
}

.selectLanguage select {
  border: 0;
  font-size: 0.625em;
  color: #555;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background: #e2e5e6 url('../images/icon_select.png');
  background-position: 100% center;
  background-repeat: no-repeat;
  outline: none;
  padding-right: 13px;
}

.selectLanguage select option {
  background: #e1e4e6;
  border: 1px solid #e1e4e6;
  height: 15px;
}

.selectLanguage select::-ms-expand {
  display: none;
}

.input-group {
  position: relative;
  margin: 50px;
  width: 200px
}

#searchinput {
  width: 100%;

}

input[type=text]::-ms-clear {
  display: none;
}

#searchclear {
  position: absolute;
  right: 3px;
  top: 0;
  bottom: 0;
  width: 15px;
  height: 14px;
  margin: auto;
  font-size: 12px;
  cursor: pointer;
  color: #ccc;
  background-color: #fff;
}

/* slider */
.slick-initialized .slick-slide {
  outline: none;
}

.slick-dots {
  overflow: hidden;
  text-align: center;
}

.slick-dots li {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
}

.slick-dots li:first-child {
  margin-left: 0;
}

.slick-dots button {
  display: block;
  overflow: hidden;
  width: 0.1875em;
  height: 0.1875em;
  border: none;
  background-color: transparent;
  background-position: -33px -40px;
  text-indent: -9999px;
  vertical-align: middle;
  -webkit-appearance: none;
  cursor: default;
}

/* 2017-01-09 dot 클릭 삭제 */
.slick-dots .slick-active button {
  width: 0.25em;
  height: 0.25em;
  background-position: -39px -40px;
}

/* link list */
.s-des-linkitem {
  margin-top: 0.5625em;
  border: 1px solid #fff;
  border-radius: 3px;
  box-shadow: 0 1px 2px #b5c0be;
  background: #fbfcfd;
}

.s-des-linkitem .s-des-overview {
  display: block;
  position: relative;
  padding: 0.8125em 36px 0.8125em 15px;
}

.s-des-linkitem .s-des-arrow {
  position: absolute;
  right: 0;
  top: 0;
  width: 36px;
  height: 100%;
}

.s-des-linkitem .s-des-arrow span {
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 7px;
  height: 13px;;
  margin: -7px 0 0 -4px;
  background-position: -5px 0;
  text-indent: -9999px;
}

.s-des-linkitem:first-child {
  margin-top: 0;
}

.s-des-linkitem .s-item-title {
  overflow: hidden;
  margin: 0;
  font-size: 0.875em;
  font-weight: normal;
  color: #555;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.s-des-linkitem .s-info {
  margin-top: 0.25em;
  line-height: 0.875em;
}

.s-des-linkitem .s-info span {
  display: inline-block;
  margin-left: 2px;
  font-size: 0.625em;
  color: #555;
  color: rgba(85, 85, 85, 0.7);
  line-height: 140%;
  vertical-align: top
}

.s-des-linkitem .s-item-text {
  margin-top: 0.182em;
  font-size: 0.6875em;
  color: #555;
  color: rgba(85, 85, 85, 0.9);
}

.s-des-linkitem .s-item-icon {
  display: inline-block;
  height: 24px;
  margin-bottom: 0.4375em;
}

.s-des-linklist.s-is-centeradjust {
  text-align: center;
}

.s-des-linklist.s-is-centeradjust .s-des-linkitem .s-des-overview {
  padding: 1.25em 36px 1.3125em;
}

.s-des-linklist .s-btn-view {
  margin-top: 0.5333em;
}

/* panel */
/* img link panel */
.s-panel-title {
  padding: 0.181818em 15px;
  background: #d4dddc;
  background: rgba(125, 155, 160, 0.15);
  font-size: 0.6875em;
  font-weight: 400;
  color: #555;
  line-height: 200%;
}

.s-panel-title span {
  display: inline-block;
  margin-top: 0.55em;
  font-size: 0.90909em;
  color: #555;
  color: rgba(85, 85, 85, 0.7);
  line-height: 1;
  vertical-align: top
}

.s-img-panel li .s-panel-overview {
  display: block;
  position: relative;
  min-height: 2.1875em;
  padding: 0.6875em 42px 0.875em 65px;
  background: url(../images/bg_panel.png) no-repeat 50% 0;
  background-size: 100% 2px;
}

.s-img-panel li:first-child > .s-panel-overview {
  background-image: none;
}

.s-img-panel li .s-panel-arrow {
  position: absolute;
  right: 0;
  top: 0;
  width: 42px;
  height: 100%;
}

.s-img-panel li .s-panel-arrow span {
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 7px;
  height: 12px;
  margin: -6px 0 0 -4px;
  background-position: 0 -40px;
  text-indent: -9999px;
}

.s-img-panel li .s-panel-arrow span.s-dim-arrow {
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 7px;
  height: 12px;
  margin: -6px 0 0 -4px;
  background-position: 0 -40px;
  text-indent: -9999px;
}

.s-img-panel li img {
  position: absolute;
  left: 15px;
  top: 50%;
  width: 38px;
  height: 38px;
  margin-top: -19px;
  border-radius: 2px;
}

.s-img-panel .s-item-title {
  overflow: hidden;
  width: 100%;
  margin: 2px 0 0;
  font-size: 0.875em;
  font-weight: 400;
  color: #555;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.s-img-panel .s-dim-title {
  color: #8d8f8f;
}

.s-img-panel .s-item-price {
  margin-top: 3px;
  font-size: 0.8125em;
  font-weight: 500;
  color: rgba(17, 17, 17, 0.9);
  line-height: 130%;
}

.s-img-panel .s-info {
  margin-top: 4px;
  line-height: 0.875em;
}

.s-img-panel .s-item-price + .s-info {
  margin-top: 3px;
}

.s-img-panel .s-info span {
  display: inline-block;
  margin-left: 2px;
  font-size: 0.625em;
  color: #555;
  color: rgba(85, 85, 85, 0.7);
  line-height: 140%;
}

.s-img-panel .s-info span:first-child {
  margin-left: 0;
}

.s-img-panel .s-item-icon {
  display: inline-block;
  height: 24px;
  margin-bottom: 12px;
}

.s-none-panel {
  padding: 1.25em 0 0.875em;
  text-align: center;
}

.s-none-panel .s-icon-caution {
  margin: 0 auto;
}

.s-none-panel p {
  font-size: 0.625em;
  color: #555;
  line-height: 200%;
}

/* img link panel - type thumb price */
.s-img-panel.s-type-thumbprice .s-panel-overview {
  padding-left: 83px;
}

.s-img-panel.s-type-thumbprice .s-item-thumbprice {
  position: absolute;
  left: 15px;
  top: 50%;
  width: 58px;
  height: 38px;
  margin-top: -19px;
  background: url(../images/m_bg_thumb_price.png) no-repeat 0 0;
  background-size: 58px 38px;
  font-size: 0.75em;
  font-weight: 500;
  color: #555;
  line-height: 38px;
  vertical-align: middle;
  text-align: center;
}

.s-img-panel.s-type-thumbprice .s-item-thumbprice:after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 100%;
  vertical-align: middle;
}

/* img link panel - type dim thumb price */
.s-img-panel.s-type-thumbprice .s-dim-thumbprice {
  background: url(../images/m_bg_dim_thumb_price.png) no-repeat 0 0;
  background-size: 58px 38px;
  font-size: 0.75em;
  font-weight: 500;
  color: #8c8d8e;
  line-height: 38px;
  vertical-align: middle;
  text-align: center;
}

.s-img-panel.s-type-thumbprice .icon-x {
  display: inline-block;
  overflow: hidden;
  width: 11px;
  height: 11px;
  text-indent: -9999px;
  position: absolute;
  top: 12px;
  left: 65px;
  background: url(../images/icon_x_x1.png) no-repeat 0 0;
  background-size: 11px 11px;
  z-index: 1
}

/* img link panel - accordion */
.s-img-panel.s-type-accordion li {
  overflow: hidden;
}

.s-img-panel.s-type-accordion .s-panel-overview {
  background-color: transparent;
}

.s-img-panel.s-type-accordion .s-panel-arrow span {
  width: 12px;
  height: 7px;
  margin: -4px 0 0 -6px;
  background-position: -15px -40px;
}

.s-img-panel.s-type-accordion .s-current .s-panel-arrow span {
  background-position: -15px -49px;
}

.s-img-panel.s-type-accordion .s-panel-arrow span.s-dim-arrow {
  width: 12px;
  height: 7px;
  margin: -4px 0 0 -6px;
  background-position: -66px -79px;
}

.s-img-panel.s-type-accordion .s-current .s-panel-arrow span.s-dim-arrow {
  background-position: -66px -88px;
}

/* text link panel */
.s-text-panel li {
  overflow: hidden;
}

.s-text-panel .s-panel-overview {
  display: block;
  overflow: hidden;
  position: relative;
  padding: 0.9375em 42px 0.9375em 13px;
  background: url(../images/bg_panel.png) no-repeat 50% 0;
  background-size: 100% 2px;
}

.s-text-panel .s-panel-arrow {
  position: absolute;
  right: 0;
  top: 0;
  width: 42px;
  height: 100%;
}

.s-text-panel .s-panel-arrow span {
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 7px;
  height: 12px;
  margin: -6px 0 0 -3px;
  background-position: 0 -40px;
  text-indent: -9999px;
}

.s-text-panel .s-item-title {
  display: table;
  table-layout: fixed;
  width: 100%;
  font-size: 0.8125em;
  font-weight: 400;
  color: #555;
  line-height: 1.53846;
}

.s-text-panel .s-item-title strong {
  display: table-cell;
  width: 5.4em;
  padding-right: 13px;
  font-weight: 500;
  color: #262626;
  text-align: center;
  vertical-align: middle;
}

.s-text-panel .s-item-title span {
  display: table-cell;
  color: #555;
  vertical-align: middle;
}

.s-text-panel li:first-child .s-panel-overview {
  background-image: none;
}

.s-text-panel.s-type-accordion .s-panel-arrow span {
  width: 12px;
  height: 7px;
  margin: -4px 0 0 -6px;
  background-position: -15px -40px;
}

.s-text-panel.s-type-accordion .s-current .s-panel-arrow span {
  background-position: -15px -49px;
}

/* accordion cont */
.s-accordion-cont {
  display: none;
  padding: 0.9375em 30px;
  background: url(../images/bg_accordioncontent.png) no-repeat 50% 0;
  background-size: 100% 100%;
}

.s-type-accordion .s-current .s-accordion-cont {
  display: block;
}

.s-accordion-cont .s-accordion-title {
  font-size: 0.75em;
  font-weight: 500;
  color: #555;
  line-height: 130%;
}

.s-accordion-cont .s-accordion-title.s-type-big {
  font-size: 0.8125em;
  color: #111;
}

.s-accordion-cont .s-accordion-title strong {
  font-weight: 500;
  color: #111;
  color: rgba(17, 17, 17, 0.9);
}

.s-accordion-cont .s-accordion-des {
  margin-top: 0.63636em;
  font-size: 0.6875em;
  font-weight: 400;
  color: #555;
  line-height: 130%;
}

.s-accordion-cont .s-btn-wrap {
  margin: 0.9375em 32px 0;
}

.s-accordion-cont .s-noline-table {
  margin: 0.625em 0;
}

/* board view */
.s-board-view {
  padding-top: 1.25em;
}

.s-board-view .s-bv-title {
  padding-bottom: 1em;
  font-size: 0.875em;
  font-weight: 400;
  color: #555;
}

.s-board-view .s-bv-img {
  padding-bottom: 1em;
}

.s-board-view .s-bv-img img {
  width: 100%;
}

.s-board-view .s-bv-text {
  font-size: 0.6875em;
  color: #555;
}

/* table */
.s-noline-table {
  width: 100%;
}

.s-noline-table th, .s-noline-table td {
  padding: 1px 0;
  font-size: 0.6875em;
  font-weight: 400;
  line-height: 160%;
}

.s-noline-table th {
  color: #555;
}

.s-noline-table td {
  color: #111;
  color: rgba(17, 17, 17, 0.9);
}

.s-noline-table2 {
  width: 100%;
}

.s-noline-table2 th, .s-noline-table2 td {
  width: 50%;
  padding: 0.1667em 0;
  font-size: 0.75em;
  font-weight: 400;
  line-height: 150%;
}

.s-noline-table2 th {
  float: left;
  color: rgba(85, 85, 85, 0.7);
}

.s-noline-table2 td {
  float: left;
  color: #555;
}

table td .s-info-tiny {
  display: block;
  font-size: 0.83333em;
  color: rgba(85, 85, 85, 0.8);
  line-height: 150%;
}

.s-noline-table3 {
  width: 72%;
  margin: 0 auto;
}

.s-noline-table3 th, .s-noline-table3 td {
  width: 50%;
  padding: 0.1667em 0;
  font-size: 0.6875em;
  font-weight: 400;
  line-height: 150%;
}

.s-noline-table3 tfoot {
  background: url(../images/bg_section_s.png) no-repeat 0 0;
  background-size: 100% 1px;
}

.s-noline-table3 tfoot th, .s-noline-table3 tfoot td {
  font-size: 0.75em;
  padding: 0.438em 0;
}

.s-noline-table3 th {
  float: left;
  color: rgba(85, 85, 85, 0.7);
}

.s-noline-table3 td {
  float: left;
  color: #555;
  text-align: right;
}

.tit_payment {
  font-size: 0.75em;
  font-weight: 400;
  color: rgba(85, 85, 85, 0.7);
  text-align: center;
  padding-bottom: 0.625em;
}

.s-phdetail-section .nodi_h.s-topline-section {
  margin-top: 0.625em;
  padding: 0.750em 0 0 0;
}

.s-noline-table3 .t3_b {
  padding-bottom: 0.313em;
}

/* info list */
.s-info-list {
  overflow: hidden;
  padding: 15px 0 3px 15px;
}

.s-info-list dl dt, .s-info-list dl dd {
  width: 50%;
  padding: 2px 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
}

.s-info-list dl dt {
  float: left;
  color: rgba(85, 85, 85, 0.7);
}

.s-info-list dl dd {
  float: left;
  color: #555;
}

/* info box */
.s-info-box {
  padding: 0.625em 7px 0.4375em;
  margin-top: 0.5em;
  border-radius: 2px;
  background: #d2dde1;
  background: rgba(0, 0, 0, 0.05);
}

.s-info-box:first-child {
  margin-top: 0;
}

.s-info-box .s-info-title {
  display: block;
  padding-left: 7px;
  font-size: 0.6875em;
  font-weight: 500;
  color: #555;
}

.s-info-box .s-info-text {
  display: block;
  padding: 0.272728em 0 0 0.636364em;
  font-size: 0.6875em;
  color: #555;
  line-height: 150%;
}

.s-info-box .s-col-btnwrap {
  margin-top: 0.9375em;
}

/* tab */
.s-tab {
  position: relative;
}

.s-tab:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
  height: 10px;
  background: url(../images/bg_shadow_bottom.png) no-repeat 50% 100%;
  background-size: 100% 10px;
}

.s-tab:after {
  content: "";
  display: block;
  clear: both;
}

.s-tab li {
  float: left;
  width: 50%;
}

.s-tab li {
  float: left;
  width: 50%;
}

.s-tab.alone li {
  float: left;
  width: 100%;
}

.s-tab li a {
  display: block;
  padding: 0.5385em 0;
  background: url(../images/tab_bar.gif) no-repeat 0 50%;
  background-size: 1px 15px;
  font-size: 0.8125em;
  color: rgba(85, 85, 85, 0.5);
  line-height: 1.53846;
  text-align: center;
}

.s-tab li:first-child a {
  background-image: none;
}

.s-tab .s-current a {
  color: #111;
}

.s-tabcontent.s-type-panel {
  margin-top: 0.5em;
}

/* panel tab */
.s-tab.s-type-panel {
  padding-bottom: 0;
  background: none;
}

.s-tab.s-type-panel li a {
  display: block;
  overflow: hidden;
  height: 3.625em;
  padding: 0;
  margin: 0 0 1px 1px;
  background: #d1dde0;
  background: rgba(0, 0, 0, 0.08);
}

.s-tab.s-type-panel li a:after {
  content: "";
  display: inline-block;
  position: static;
  width: 1px;
  height: 100%;
  margin: 0;
  background: none;
  vertical-align: middle;
}

.s-tab.s-type-panel li span {
  display: inline-block;
  width: 98%;
  vertical-align: middle;
  font-size: 1em;
  color: #555;
  color: rgba(85, 85, 85, 0.8);
  line-height: 1.3;
  word-break: break-all;
}

.s-tab.s-type-panel .s-first a {
  margin-left: 0;
}

.s-tab.s-type-panel .s-current a {
  background: #fff;
}

.s-tab.s-type-panel .s-current a span {
  color: #111;
}

.s-tab.s-type4 li {
  width: 25%;
}

/* app img title */
.s-app-view {
  text-align: center;
}

.s-app-view img {
  width: 100px;
  height: auto;
  margin-bottom: 0.625em;
  border-radius: 2px;
}

.s-app-view .s-app-title {
  font-size: 1.25em;
  font-weight: 400;
  color: #555;
  line-height: 150%;
  text-align: center;
}

.s-app-view .s-app-subtitle {
  display: block;
  margin-top: 0.5em;
  font-size: 0.75em;
  font-weight: 400;
  color: #555;
  line-height: 150%;
  text-align: center;
  word-break: break-all;
}

/* header */
.s-header .s-header-gnb {
  position: relative;
  padding: 0.8125em 15px 0.75em;
  background: url(../images/m_bg_gnb.png) no-repeat 0 0;
  background-size: 100% 100%;
}

.s-header .s-logo {
  height: 1.125em;
  margin: 0;
  font-size: 1em;
}

.s-header .s-logo a img {
  width: 150px;
  height: 18px;
  vertical-align: middle;
}

.s-header .s-util {
  position: absolute;
  right: 7px;
  top: 0.625em;
}

.s-header .s-util .s-log {
  float: left;
  margin-right: 10px;
}

.s-log .s-btn-log {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 23px;
  height: 23px;
  text-align: center;
}

.s-log .s-btn-log span {
  display: inline-block;
  overflow: hidden;
  width: 12px;
  height: 18px;
  margin-top: 2px;
  background-position: 0 -59px;
  text-indent: -9999px;
  vertical-align: top;
}

.s-header .s-util .s-quickmenu {
  float: left;
}

.s-quickmenu .s-btn-quickmenu {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 23px;
  height: 23px;
}

.s-quickmenu .s-btn-quickmenu span {
  display: inline-block;
  overflow: hidden;
  width: 3px;
  height: 18px;
  margin-top: 2px;
  background-position: 0 0;
  text-indent: -9999px;
  vertical-align: top;
}

.s-navi {
  overflow: hidden;
  position: relative;
  background: url(../images/m_bg_navi.png) no-repeat 0 0;
  background-size: 100% 100%;
}

.s-navi:before, .s-navi:after {
  content: "";
  position: absolute;
  top: 0;
  width: 20px;
  height: 2.125em;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% 100%;
  z-index: 15;
}

.s-navi:before {
  left: 0;
  background-image: url(../images/navi_grd_left.png);
}

.s-navi:after {
  right: 0;
  background-image: url(../images/navi_grd_right.png);
}

.s-navi.s-left-none:before {
  display: none;
}

.s-navi ul {
  overflow: hidden;
  width: 200%;
}

.s-navi li {
  float: left;
  padding-left: 20px
}

.s-navi li a {
  display: block;
  padding: 0.6155em 0;
  font-size: 0.8125em;
  color: #a7c1c9;
  color: rgba(255, 255, 255, 0.4)
}

.s-navi .s-current a {
  color: #fff;
}

.s-navi .s-navi-dim a {
  color: #7e979d;
  color: rgba(255, 255, 255, 0.2)
}

.s-sub-header {
  position: relative;
  padding: 0.6875em 15px 0.625em 32px;
  background: url(../images/m_bg_gnb.png) no-repeat 0 0;
  background-size: 100% 100%;
}

.s-sub-header .s-sub-title {
  font-size: 0.9375em;
  font-weight: 400;
  color: #fff;
  line-height: 1.4667em;
}

.s-sub-header .s-btn-back {
  position: absolute;
  left: 0;
  top: 0;
  width: 4.6875em;
  height: 2.6875em;
}

.s-sub-header .s-btn-back span {
  overflow: hidden;
  position: absolute;
  left: 13px;
  top: 50%;
  width: 9px;
  height: 14px;
  margin-top: -7px;
  background-position: 0 -20px;
  text-indent: -9999px;
}

/* footer */
.footer {
  position: relative;
  padding: 1.875em 0 0.9375em;
  text-align: center;
  line-height: 1.0;
  opacity: 0.9
}

.footer a {
  color: #555
}

.footer .t_link1 {
  display: block;
  font-size: 0.625em;
  font-weight: 500;
}

.footer .t_link2 {
  margin-bottom: 0.9375em;
}

.footer .t_link2 a {
  font-size: 0.625em;
  padding: 0 5px;
  vertical-align: middle
}

.footer .t_link2 span {
  vertical-align: middle;
  font-size: 0.625em;
}

.footer .t_email {
  font-size: 0.625em;
  color: rgba(85, 85, 85, 0.9);
}

.footer .t_copyright {
  margin-top: 0.4em;
  font-size: 0.625em;
  font-weight: 500;
  color: rgba(85, 85, 85, 0.9);
}

/* container */
/* login */
.welcome_wrapping {
  position: relative;
  overflow: hidden;
  height: 17.9375em;
}

.w_img {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../images/m_bg_sign.jpg) no-repeat 50% 50%;
  background-size: cover;
  -webkit-animation: welcomeBg 1s ease-out 1 forwards;
  -moz-animation: welcomeBg 1s ease-out 1 forwards;
  animation: welcomeBg 1s ease-out 1 forwards;
  z-index: 1
}

@-webkit-keyframes welcomeBg {
  from {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2)
  }
  to {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1)
  }
}

@keyframes welcomeBg {
  from {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2)
  }
  to {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1)
  }
}

.welcome {
  position: relative;
  padding: 2.8125em 15px 0;
  height: 327px;
  z-index: 2;
}

.welcome .sign_info {
  color: #fff;
  text-align: center
}

.welcome .sign_info strong {
  display: block;
  margin-bottom: 0.4474em;
  font-size: 2.375em;
  font-weight: 400;
  line-height: 100%
}

.welcome .sign_info span {
  display: block;
  margin-bottom: 2.5em;
  font-size: 0.875em;
  line-height: 1.357
}

.welcome .btn_wrapping {
  width: 74.2%;
  max-width: 250px;
  margin: 0 auto
}

.welcome_tv .s-tvcode-section {
  padding-top: 1.4375em;
  margin-top: 0;
  background: none
}

/* E login sign In 쪽도 동일해질경우 이걸로 대체하세여 */
.welcome_tv .s-tvcode-section .s-tvcode-head {
  position: relative;
  margin-bottom: 0.8125em;
}

.welcome_tv .s-tvcode-section .s-tvcode-head strong {
  display: block;
  padding-right: 26px;
  font-size: 0.875em;
  font-weight: 400;
  color: #555;
  line-height: 130%
}

.welcome_tv .s-tvcode-section .form-group {
  margin: 0
}

.welcome_tv .s-tvcode-section .s-tvcode-head .s-link-help {
  position: absolute;
  right: 0;
  top: auto;
  bottom: 0
}

/* payment method */
/* pay-method-slider */
.s-pay-slider {
  position: relative;
  padding-top: 36px;
}

.s-pay-slider .s-pay-item {
  position: relative;
  box-sizing: content-box;
  padding-bottom: 2.8125em;
  text-align: center;
}

.s-pay-slider .s-pay-item:focus {
  outline: none;
  border: none;
}

.s-pay-slider .s-pay-item .s-card-cont {
  position: relative;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.s-pay-slider .slick-current .s-pay-item .s-card-cont {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.s-pay-slider .s-pay-item .s-card-cont > img, .s-pay-slider .s-pay-item .s-card-cont > a > img {
  width: 100%;
}

.s-pay-slider .s-pay-item .s-card-company {
  position: absolute;
  right: 8%;
  top: 8%;
  width: 60%;
  height: 27%;
}

.s-card-company-left {
  position: absolute;
  left: 6%;
  top: 8%;
  width: 60%;
  height: 27%;
}

.s-card-company-left > img{
  width: 40%;
}

.s-pay-slider .s-pay-item .s-card-company img {
  width: 100%;
}

.s-pay-slider .s-pay-item .s-card-fullnumber {
  position: absolute;
  left: 0;
  top: 56.5%;
  width: 100%;
  height: 10.5%;
  padding: 0 5%;
}

.s-pay-slider .s-pay-item .s-card-fullnumber:after {
  content: "";
  display: block;
  clear: both;
}

.s-pay-slider .s-pay-item .s-card-fullnumber .s-card-number {
  float: left;
  width: 25%;
  font-size: 0;
  text-align: center;
}

.s-pay-slider .s-pay-item .s-card-onlynum {
  position: absolute;
  right: 5.2%;
  top: 56.5%;
  font-size: 14px;
  text-align: left;
}

.s-pay-slider .s-pay-item .s-card-onlynum span {
  width: 10px;
}

.s-pay-slider .s-pay-item .s-card-text {
  position: absolute;
  left: 0;
  top: 55%;
  width: 100%;
  font-size: 14px;
  color: #555;
  text-align: center;
}

.s-pay-slider .s-pay-item .s-card-text-icon {
  position: absolute;
  left: 0;
  top: 33.3%;
  width: 100%;
  font-size: 20px;
  color: #555;
  text-align: center;
}

.s-pay-slider .slick-dots {
  position: absolute;
  left: 0;
  bottom: 0.8125em;
  width: 100%;
}

.s-recentorder-list {
  position: relative;
  height: 13.7em;
}

.s-recentorder-list .s-des-linklist .s-section-title {
  margin-top: 2.8125em;
}

/* for react slick -1 */
.s-des-linklist {
  z-index: -1;
}

/* for react slick -2 */
.slick-current > div > .s-des-linklist {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 1;
  -webkit-transition: all 500ms;
  -moz-transition: all 500ms;
  -ms-transition: all 500ms;
  -o-transition: all 500ms;
  transition: all 500ms;
}

.s-pay-slider .slick-arrow {
  position: absolute;
  top: 50%;
  width: 26px;
  height: 50px;
  border: 0;
  background: url(../images/btn_slider_arrow.png) no-repeat;
  text-indent: -9999px;
}

.s-pay-slider .slick-prev {
  left: -100px;
  background-position: 0 0;
}

.s-pay-slider .slick-next {
  right: -100px;
  background-position: -30px 0;
}

.s-pay-slider .slick-prev.slick-disabled {
  background-position: 0 -50px;
}

.s-pay-slider .slick-next.slick-disabled {
  background-position: -30px -50px;
}

/* add method */
.s-addmethod-section {
  padding-top: 2.1875em;
}

.s-addmethod-section .s-btn {
  margin-top: 110px;
}

.s-addpaypal-section {
  padding-top: 2.1875em;
}

.s_tel_code {
  padding-top: 2.375em;
}

.s_tel_code .authentication_code {
  margin-bottom: 1.5625em;
  text-align: center;
  color: #555
}

.s_tel_code .authentication_code dt {
  padding-bottom: 0.9286em;
  background: url(../images/m_bg_card_tel.png) no-repeat 50% 100%;
  background-size: auto 1px;
  font-weight: normal;
  font-size: 0.875em;
  line-height: 1.0
}

.s_tel_code .authentication_code dd {
  line-height: 1.0;
}

.s_tel_code .authentication_code .code_n {
  padding: 0.2727em 0;
  font-size: 3.4375em;
  color: #111;
  font-weight: 300
}

.s_tel_code .authentication_code .expires {
  font-size: 0.875em;
}

.s_tel_code .info {
  text-align: center;
  margin-bottom: 2.9167em;
  font-size: 0.75em;
  color: #555
}

.s_tel_code .info + .one_btn_wrapping {
  margin-top: 0
}

/* cancellation guide */
.s-cancellation-section {
  padding-top: 1.125em;
}

.s-cancellation-title {
  font-size: 0.938em;
  font-weight: 500;
  color: #555;
  line-height: 130%;
  padding: 0 0 0 0.438em;
  color: #1c6d81;
}

.s-list-title {
  font-size: 0.813em;
  font-weight: 500;
  color: #555555;
  line-height: 1.42857;
  padding-bottom: 0.556em;
}

.s-cancellation-section .s-topline-section-cc {
  margin-top: 0.938em;
  padding: 1.056em 0 0.1875em;
  background: url(../images/bg_section.png) no-repeat 50% 0;
}

.s-list-item li {
  font-size: 0.625em;
  font-weight: 400;
  color: #555;
  line-height: 170%;
}

.s-list-info {
  font-size: 0.563em;
  color: #555;
  color: rgba(85, 85, 85, 0.7);
  line-height: 1.42857;
  padding-top: 0.750em;
}

.s-list-info span {
  font-weight: 500;
  color: #000000;
  color: rgba(0, 0, 0, 0.7);
}

.list-group {
  padding: 0 0.375em 0 0.375em;
}

.list-group:last-child {
  padding-top: 0.750em;
}

.fc_green {
  color: #1c6d81;
  font-weight: 500;
}

.fc_bold {
  font-weight: 500;
}

.mob_none {
  display: none;
}

/* completed */
.completed_add_token {
  text-align: center;
}

.completed_add_token .msg {
  margin-top: 1em;
  margin-bottom: 8.5625em;
}

.completed_add_token .msg strong {
  display: block;
  font-weight: normal;
  font-size: 1.45em;
  margin-bottom: 0.85em;
  color: rgb(85, 85, 85);
}

.completed_add_token .msg p {
  background: url(../images/bg_completed_paymethod.png) no-repeat 50% 20%;
  background-size: 170px auto;
  padding-top: 14.3333em;
  font-size: 0.975em;
  line-height: 150%;
  padding-right: 2.5em;
  padding-left: 2.5em;
  color: rgb(85, 85, 85);
}

.completed_add_token .msg p .token_info {
  display: block;
  color: rgb(28, 116, 167);
}

.completed_add_token .msg_continue {
  margin-bottom: 1.25em;
  font-size: 0.75em
}

.completed_add_token .btn_wrapping {
  width: 53%;
  margin: 0 auto;
}

.completed_payment {
  text-align: center;
}

.completed_payment .msg {
  margin-top: 2em;
}

.completed_payment .msg strong {
  display: block;
  font-weight: normal;
  font-size: 1.45em;
  margin-bottom: 0.425em;
  color: rgb(85, 85, 85);
}

.completed_payment .msg p {
  background: url(../images/bg_completed_paymethod.png) no-repeat 50% 20%;
  background-size: 170px auto;
  padding-top: 13.3333em;
  font-size: 0.975em;
  line-height: 150%;
  padding-right: 2.5em;
  padding-left: 2.5em;
  color: rgb(85, 85, 85);
}

.completed_payment .msg p .token_info {
  display: block;
  color: rgb(28, 116, 167);
}

.completed_payment .msg_continue {
  margin-bottom: 2.25em;
  font-size: 0.75em
}

.completed_payment .btn_wrapping {
  width: 53%;
  margin: 0 auto;
}

/* purchase history */
.s-pullpush-list {
  position: relative;
  padding-bottom: 36px;
}

.s-pullpush-list .s-lazy-loading {
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: -15px;
}

/* purchase history detail */
.s-phdetail-section {
  padding: 2.75em 0;
}

.s-phdetail-section .s-topline-section {
  margin-top: 1.0625em;
  padding-bottom: 0.1875em;
}

.s-phdetail-section .s-contactus {
  margin: 0.8125em -37px 0;
}

.s-phdetail-section .s-btn {
  margin-top: 0.8125em;
}

/* promotional codes */
.s-addcode-section {
  padding-top: 1.375em;
  padding-bottom: 1.5625em;
  background: #d5dddf url(../images/m_bg_codes.png) no-repeat 50% 50%;
  background-size: 100% 100%;
}

.s-addcode-section .s-form-title {
  text-align: left;
  line-height: 1.3;
}

.s-addcode-section .s-form-notice {
  display: block;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  font-size: 0.625em;
  color: rgba(85, 85, 85, 0.7);
  letter-spacing: 0;
}

/* add paypal */
.s_paypal_info {
  margin-top: 1.2em;
  text-align: center;
  line-height: 1.3em;
  font-size: 0.625em;
  opacity: 0.7
}

.s_paypal_info a {
  color: #000
}

/* error */
.s-error-section {
  padding-top: 2.5em;
}

.s-error-section:before {
  content: "";
  display: block;
  width: 38px;
  height: 33px;
  margin: 0 auto;
  background-position: 0 -79px;
}

.s-error-section .s-error-message {
  margin-top: 1.25em;
  font-size: 0.875em;
  color: #555;
  text-align: center;
  line-height: 150%;
}

.s-error-section .one_btn_wrapping {
  margin-top: 2.1875em;
}

/* dropdown style */
.dropdown .s-dropdown-arrow {
  display: none;
  position: absolute;
  width: 8px;
  height: 6px;
  background-position: -33px -49px;
  z-index: 1000;
}

.dropdown .btn-default .s-count {
  display: inline-block;
  margin-top: 0.1em;
  font-size: 0.909091em;
  color: #555;
  color: rgba(85, 85, 85, 0.7);
  line-height: 1;
  vertical-align: top
}

.s-log .dropdown-menu {
  right: -9px;
  top: 2.0625em;
  padding: 15px;
}

.s-log .dropdown-menu:after {
  right: 15px;
}

.s-log .dropdown-menu .s-log-account {
  display: block;
  font-size: 0.8125em;
  color: #555;
  text-align: center;
  line-height: 150%;
}

.s-log .dropdown-menu .s-btn {
  width: 11.334em;
  margin-top: 9px;
}

.s-log .s-dropdown-arrow {
  right: 7px;
  bottom: -0.68em;
}

.s-quickmenu .dropdown-menu {
  right: -4px;
  top: 2.0625em;
  min-width: 6.875em;
  padding: 0 8px;
}

.s-quickmenu .s-dropdown-arrow {
  right: 7px;
  bottom: -0.68em;
}

.s-sorting {
  padding: 0.3125em 0 0.25em;
}

.s-sorting .dropdown-menu {
  min-width: 10.625em;
  top: 100%;
}

.s-sorting .dropdown-menu .s-current a {
  position: relative;
}

.s-sorting .dropdown-menu .s-current a:after {
  content: "selected";
  overflow: hidden;
  position: absolute;
  right: 8px;
  top: 50%;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  background-position: -15px -59px;
  text-indent: -9999px;
}

.s-sorting.dropdown .s-dropdown-arrow {
  right: 12px;
  bottom: 0;
}

.s-sorting.s-sorting-purchase .dropdown-menu {
  left: 3px;
}

.s-sorting.s-sorting-method .dropdown-menu {
  right: 3px;
}

.s-sorting.s-sorting-subscribe .dropdown-menu {
  left: 3px;
}

.s-benefitinfo {
  display: inline-block;
  position: relative;
  width: 11px;
  height: 12px;
  margin-left: 3px;
  vertical-align: middle;
}

.s-btn-benefitinfo {
  display: block;
  width: 11px;
  height: 11px;
  border: none;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  background-position: -41px -86px;
  -webkit-appearance: none;
  text-indent: -9999px;
}

.s-benefitinfo .dropdown-menu {
  left: auto;
  right: -17px;
  top: 2.3em;
  width: 200px;
  padding: 10px 12px;
  font-size: 0.8333335em;
  color: #555;
}

.s-benefitinfo .s-dropdown-arrow {
  right: 1px;
  bottom: -1.4em;
  font-size: 0.8333335em;
}

/* dim */
.s-dim {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  filter: alpha(opacity=20);
  opacity: 0.2;
  z-index: 20;
}

/* bootstrap override */
.form-group {
  margin: 0.9375em 0 0;
}

.form-group:first-child {
  margin-top: 0;
}

.dropdown .btn-default {
  height: 2.454545em;
  padding: 0.5455em 23px 0.5455em 12px;
  border: none;
  background: none;
  font-size: 0.6875em;
  color: #555;
  line-height: 1.363636;
  -webkit-appearance: none;
  text-align: left;
}

.dropdown .btn-default .caret {
  position: absolute;
  right: 12px;
  top: 50%;
  width: 6px;
  height: 4px;
  margin-top: -2px;
  border: none;
  background: url(../images/icon_select.png) no-repeat 0 0;
  background-size: 6px 4px;
}

.dropdown.open > .dropdown-toggle.btn-default {
  border: none;
  background: none;
}

.dropdown-menu {
  min-width: auto;
  padding: 0 4px;
  margin-top: 0;
  border: none;
  border-radius: 2px;
  background: #f2f4f5;
  font-size: 1em;
}

.dropdown-menu > li {
  padding-top: 1px;
  background: url(../images/bg_panel.png) no-repeat 50% 0;
  background-size: 100% 2px
}

.dropdown-menu > li:first-child {
  padding-top: 0;
  background: none;
}

.dropdown-menu > li > a {
  padding: 10px 8px;
  font-size: 0.8125em;
  color: #555;
  line-height: 150%;
}

/* remodal custom */
.remodal-overlay {
  background: #000;
  filter: alpha(opacity=70);
  opacity: 0.7;
}

.remodal-wrapper:after {
  vertical-align: middle;
}

.remodal {
  width: 99%;
  vertical-align: middle;
}

.remodal-contents {
  display: block;
  width: 298px;
  padding: 0.875em 0 0.9375em;
  margin: 0 auto;
  background: no-repeat 50% 50%;
  background-size: 100% 100%;
}

.remodal-contents.remodal-type-1line .remodal-textarea, .remodal-contents.remodal-type-1line .remodal-contarea {
  min-height: 1.5em;
}

.remodal-contents.remodal-type-2line {
  background-image: url(../images/m_bg_popup_line2.png)
}

.remodal-contents.remodal-type-2line .remodal-textarea, .remodal-contents.remodal-type-2line .remodal-contarea {
  min-height: 3em;
}

.remodal-contents.remodal-type-4line {
  background-image: url(../images/m_bg_popup_line4.png)
}

.remodal-contents.remodal-type-4line .remodal-textarea, .remodal-contents.remodal-type-4line .remodal-contarea {
  min-height: 6em;
}

.remodal-contents.remodal-type-longline {
  background-image: url(../images/m_bg_popup_linel.png)
}

.remodal-contents.remodal-type-longline .remodal-textarea, .remodal-contents.remodal-type-longline .remodal-contarea {
  min-height: 12em;
}

.remodal-contents.remodal-type-withlistbox {
  width: 390px;
  background-image: url(../images/m_bg_popup_linel.png);
}

.remodal-contents.remodal-type-withlistbox .remodal-textarea, .remodal-contents.remodal-type-withlistbox .remodal-contarea {
  min-height: 3em;
}

.remodal-contents.remodal-type-moreview {
  background-image: url(../images/m_bg_popup_linel.png)
}

.remodal-textarea, .remodal-contarea {
  position: relative;
  box-sizing: content-box;
  margin: 0 15px;
  font-size: 0.8125em;
  line-height: 150%;
}

/* width changed from 1px to 100% */
.remodal-textarea:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.remodal-textarea.remodal-scroll, .remodal-contarea.remodal-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: 12em;
}

.remodal-imgarea img {
  width: 100%;
}

.remodal-title {
  display: block;
  margin-bottom: 7px;
  font-size: 0.8125em;
  font-weight: 500;
  color: #555;
}

.remodal-text {
  display: inline-block;
  width: 99%;
  font-size: 1em;
  line-height: 150%;
  color: #555;
  vertical-align: middle;
  word-break: break-word;
}

.remodal-contents.remodal-type-moreview .remodal-text {
  display: -webkit-box;
  overflow: hidden;
  height: 7.5em;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  text-overflow: ellipsis;
}

.remodal-link-view:hover, .remodal-link-view:active, .remodal-link-view:link, .remodal-link-view:visited {
  display: inline-block;
  font-size: 1em;
  line-height: 150%;
  color: #555;
  text-decoration: underline;
}

.remodal-textarea.remodal-textarea-t, .remodal-contarea.remodal-contarea-t {
  font-size: 0.6875em;
  line-height: 150%;
}

.remodal-textarea.remodal-textarea-t:after {
  display: none;
}

.remodal-textarea.remodal-textarea-t .remodal-title, .remodal-contarea.remodal-contarea-t .remodal-title {
  font-size: 1.090909em;
}

.remodal-textarea.remodal-textarea-t .remodal-text, .remodal-contarea.remodal-contarea-t .remodal-text {
  font-size: 1em;
  line-height: 150%;
  text-align: left;
  word-break: break-all;
}

.remodal-type-toast {
  width: 269px;
  padding: 8px 10px 7px;
  margin: 0 auto;
  background: #fdfdfd;
  box-shadow: 0 0 10px #a7aaab;
}

.remodal-type-toast .remodal-text {
  display: block;
  width: 100%;
  font-size: 0.8125em;
  color: rgba(17, 17, 17, 0.9);
}

.remodal-link-bottom {
  display: block;
  margin-top: 0.75em;
}

.remodal-btnarea {
  padding: 0.8125em 40px 0;
}

.remodal-btnarea.s-col-btnwrap {
  padding: 0.8125em 15px 0;
}

.remodal-btnarea .s-btn-default {
  letter-spacing: -1px;
}

.remodal-slider-wrap {
  padding: 0 15px;
}

.remodal-slider-wrap .slick-arrow {
  overflow: hidden;
  position: absolute;
  top: 3.35em;
  width: 9px;
  height: 16px;
  border: none;
  box-shadow: none;
  background-color: transparent;
  text-indent: -9999px;
}

.remodal-slider-wrap .slick-prev {
  left: 10px;
  background-position: -33px -59px;
}

.remodal-slider-wrap .slick-next {
  right: 10px;
  background-position: -44px -59px;
}

.remodal-slider-wrap .remodal-contarea {
  margin: 0;
}

.remodal-slider-wrap .remodal-contarea .remodal-title, .remodal-slider-wrap .remodal-contarea .remodal-text, .remodal-slider-wrap .remodal-contarea .remodal-imgarea, .remodal-slider-wrap .remodal-contarea .remodal-link-bottom {
  padding: 0 15px;
}

.remodal-slider-wrap .remodal-contarea .remodal-imgarea {
  overflow: hidden;
  height: 7.5em;
}

.remodal-slider-wrap .remodal-btnarea {
  padding: 0;
  margin: 1.875em 0 0;
}

.remodal-slider-wrap .slick-dots {
  position: absolute;
  left: 0;
  bottom: 2.98em;
  width: 100%;
}

.remodal-hide-overlay.remodal-overlay {
  display: none !important;
}

/* lazy load */
.s-lazy-loading {
  display: block;
  width: 29px;
  height: 29px;
  background: url(../images/loading/loading_whites_00.png) 0 0 no-repeat;
  -webkit-background-size: 29px 29px;
  background-size: 29px 29px;
  overflow: hidden;
  -webkit-animation-name: lazyLodingSpinner;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-name: lazyLodingSpinner;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes lazyLodingSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes lazyLodingSpinner {
  0% {
    background-image: url(../images/loading/loading_whites_00.png);
  }
  3% {
    background-image: url(../images/loading/loading_whites_01.png);
  }
  6% {
    background-image: url(../images/loading/loading_whites_02.png);
  }
  9% {
    background-image: url(../images/loading/loading_whites_03.png);
  }
  11% {
    background-image: url(../images/loading/loading_whites_04.png);
  }
  13% {
    background-image: url(../images/loading/loading_whites_05.png);
  }
  16% {
    background-image: url(../images/loading/loading_whites_06.png);
  }
  19% {
    background-image: url(../images/loading/loading_whites_07.png);
  }
  21% {
    background-image: url(../images/loading/loading_whites_08.png);
  }
  25% {
    background-image: url(../images/loading/loading_whites_09.png);
  }
  29% {
    background-image: url(../images/loading/loading_whites_10.png);
  }
  31% {
    background-image: url(../images/loading/loading_whites_11.png);
  }
  34% {
    background-image: url(../images/loading/loading_whites_12.png);
  }
  37% {
    background-image: url(../images/loading/loading_whites_13.png);
  }
  40% {
    background-image: url(../images/loading/loading_whites_14.png);
  }
  43% {
    background-image: url(../images/loading/loading_whites_15.png);
  }
  47% {
    background-image: url(../images/loading/loading_whites_16.png);
  }
  50% {
    background-image: url(../images/loading/loading_whites_17.png);
  }
  53% {
    background-image: url(../images/loading/loading_whites_18.png);
  }
  56% {
    background-image: url(../images/loading/loading_whites_19.png);
  }
  59% {
    background-image: url(../images/loading/loading_whites_20.png);
  }
  61% {
    background-image: url(../images/loading/loading_whites_21.png);
  }
  63% {
    background-image: url(../images/loading/loading_whites_22.png);
  }
  69% {
    background-image: url(../images/loading/loading_whites_23.png);
  }
  72% {
    background-image: url(../images/loading/loading_whites_24.png);
  }
  75% {
    background-image: url(../images/loading/loading_whites_25.png);
  }
  77% {
    background-image: url(../images/loading/loading_whites_26.png);
  }
  80% {
    background-image: url(../images/loading/loading_whites_27.png);
  }
  83% {
    background-image: url(../images/loading/loading_whites_28.png);
  }
  85% {
    background-image: url(../images/loading/loading_whites_29.png);
  }
  89% {
    background-image: url(../images/loading/loading_whites_30.png);
  }
  91% {
    background-image: url(../images/loading/loading_whites_31.png);
  }
  93% {
    background-image: url(../images/loading/loading_whites_32.png);
  }
  95% {
    background-image: url(../images/loading/loading_whites_33.png);
  }
  97% {
    background-image: url(../images/loading/loading_whites_34.png);
  }
  100% {
    background-image: url(../images/loading/loading_whites_35.png);
  }
}

/* page loading spinner */
.s-loading-pop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
}

.s-loading-spinner {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  background: url(../images/loading/loading_whitel_00.png) no-repeat 0 0;
  background-size: 100% 100%;
  text-indent: -9999px;
  overflow: hidden;
  -webkit-animation-name: LoadingSpinner;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-name: LoadingSpinner;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes LoadingSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes LoadingSpinner {
  0% {
    background-image: url(../images/loading/loading_whitel_00.png);
  }
  3% {
    background-image: url(../images/loading/loading_whitel_01.png);
  }
  6% {
    background-image: url(../images/loading/loading_whitel_02.png);
  }
  9% {
    background-image: url(../images/loading/loading_whitel_03.png);
  }
  11% {
    background-image: url(../images/loading/loading_whitel_04.png);
  }
  13% {
    background-image: url(../images/loading/loading_whitel_05.png);
  }
  16% {
    background-image: url(../images/loading/loading_whitel_06.png);
  }
  19% {
    background-image: url(../images/loading/loading_whitel_07.png);
  }
  21% {
    background-image: url(../images/loading/loading_whitel_08.png);
  }
  25% {
    background-image: url(../images/loading/loading_whitel_09.png);
  }
  29% {
    background-image: url(../images/loading/loading_whitel_10.png);
  }
  31% {
    background-image: url(../images/loading/loading_whitel_11.png);
  }
  34% {
    background-image: url(../images/loading/loading_whitel_12.png);
  }
  37% {
    background-image: url(../images/loading/loading_whitel_13.png);
  }
  40% {
    background-image: url(../images/loading/loading_whitel_14.png);
  }
  43% {
    background-image: url(../images/loading/loading_whitel_15.png);
  }
  47% {
    background-image: url(../images/loading/loading_whitel_16.png);
  }
  50% {
    background-image: url(../images/loading/loading_whitel_17.png);
  }
  53% {
    background-image: url(../images/loading/loading_whitel_18.png);
  }
  56% {
    background-image: url(../images/loading/loading_whitel_19.png);
  }
  59% {
    background-image: url(../images/loading/loading_whitel_20.png);
  }
  61% {
    background-image: url(../images/loading/loading_whitel_21.png);
  }
  63% {
    background-image: url(../images/loading/loading_whitel_22.png);
  }
  69% {
    background-image: url(../images/loading/loading_whitel_23.png);
  }
  72% {
    background-image: url(../images/loading/loading_whitel_24.png);
  }
  75% {
    background-image: url(../images/loading/loading_whitel_25.png);
  }
  77% {
    background-image: url(../images/loading/loading_whitel_26.png);
  }
  80% {
    background-image: url(../images/loading/loading_whitel_27.png);
  }
  83% {
    background-image: url(../images/loading/loading_whitel_28.png);
  }
  85% {
    background-image: url(../images/loading/loading_whitel_29.png);
  }
  89% {
    background-image: url(../images/loading/loading_whitel_30.png);
  }
  91% {
    background-image: url(../images/loading/loading_whitel_31.png);
  }
  93% {
    background-image: url(../images/loading/loading_whitel_32.png);
  }
  95% {
    background-image: url(../images/loading/loading_whitel_33.png);
  }
  97% {
    background-image: url(../images/loading/loading_whitel_34.png);
  }
  100% {
    background-image: url(../images/loading/loading_whitel_35.png);
  }
}

/* form loading spinner */
.form_spinner {
  position: absolute;
  right: 11px;
  top: 50%;
  margin-top: -9px;
  width: 18px;
  height: 18px;
  background: url(../images/loading/loading_whitexs_00.png) no-repeat 0 0;
  background-size: 100% 100%;
  text-indent: -9999px;
  overflow: hidden;
  -webkit-animation-name: Spinner;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-name: Spinner;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.card_spinner {
  position: absolute;
  right: 50%;
  top: 50%;
  margin-top: -16px;
  margin-right: -16px;
  width: 33px;
  height: 33px;
  background: url(../images/loading/loading_whitexs_00.png) no-repeat 0 0;
  background-size: 100% 100%;
  text-indent: -9999px;
  overflow: hidden;
  -webkit-animation-name: Spinner;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-name: Spinner;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes Spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes Spinner {
  0% {
    background-image: url(../images/loading/loading_whitexs_00.png);
  }
  3% {
    background-image: url(../images/loading/loading_whitexs_01.png);
  }
  6% {
    background-image: url(../images/loading/loading_whitexs_02.png);
  }
  9% {
    background-image: url(../images/loading/loading_whitexs_03.png);
  }
  11% {
    background-image: url(../images/loading/loading_whitexs_04.png);
  }
  13% {
    background-image: url(../images/loading/loading_whitexs_05.png);
  }
  16% {
    background-image: url(../images/loading/loading_whitexs_06.png);
  }
  19% {
    background-image: url(../images/loading/loading_whitexs_07.png);
  }
  21% {
    background-image: url(../images/loading/loading_whitexs_08.png);
  }
  25% {
    background-image: url(../images/loading/loading_whitexs_09.png);
  }
  29% {
    background-image: url(../images/loading/loading_whitexs_10.png);
  }
  31% {
    background-image: url(../images/loading/loading_whitexs_11.png);
  }
  34% {
    background-image: url(../images/loading/loading_whitexs_12.png);
  }
  37% {
    background-image: url(../images/loading/loading_whitexs_13.png);
  }
  40% {
    background-image: url(../images/loading/loading_whitexs_14.png);
  }
  43% {
    background-image: url(../images/loading/loading_whitexs_15.png);
  }
  47% {
    background-image: url(../images/loading/loading_whitexs_16.png);
  }
  50% {
    background-image: url(../images/loading/loading_whitexs_17.png);
  }
  53% {
    background-image: url(../images/loading/loading_whitexs_18.png);
  }
  56% {
    background-image: url(../images/loading/loading_whitexs_19.png);
  }
  59% {
    background-image: url(../images/loading/loading_whitexs_20.png);
  }
  61% {
    background-image: url(../images/loading/loading_whitexs_21.png);
  }
  63% {
    background-image: url(../images/loading/loading_whitexs_22.png);
  }
  69% {
    background-image: url(../images/loading/loading_whitexs_23.png);
  }
  72% {
    background-image: url(../images/loading/loading_whitexs_24.png);
  }
  75% {
    background-image: url(../images/loading/loading_whitexs_25.png);
  }
  77% {
    background-image: url(../images/loading/loading_whitexs_26.png);
  }
  80% {
    background-image: url(../images/loading/loading_whitexs_27.png);
  }
  83% {
    background-image: url(../images/loading/loading_whitexs_28.png);
  }
  85% {
    background-image: url(../images/loading/loading_whitexs_29.png);
  }
  89% {
    background-image: url(../images/loading/loading_whitexs_30.png);
  }
  91% {
    background-image: url(../images/loading/loading_whitexs_31.png);
  }
  93% {
    background-image: url(../images/loading/loading_whitexs_32.png);
  }
  95% {
    background-image: url(../images/loading/loading_whitexs_33.png);
  }
  97% {
    background-image: url(../images/loading/loading_whitexs_34.png);
  }
  100% {
    background-image: url(../images/loading/loading_whitexs_35.png);
  }

}

@media (min-width: 768px) {
  /* payment method */
  .s-pay-slider {
    width: 356px;
    padding-top: 70px;
    margin: 0 auto;
  }

  .s-pay-slider .slick-arrow {
    top: 140px;
  }

  .s-noline-table2 {
    width: 120%;
    margin-left: 23px
  }
}

@media (min-width: 992px) {
  html, body {
    font-size: 16px;
  }

  html {
    background: #e0e4e5 url(../images/bg_container.jpg) no-repeat 50% 50%;
    background-size: cover;
    background-attachment: fixed
  }

  /* section */
  .s-section {
    padding-top: 40px;
  }

  .s-section + .s-topline-section {
    margin-top: 15px;
    padding: 15px 0;
  }

  .s-section-p {
    padding-top: 20px;
  }

  /* layout */
  .s-wrap, .s-wrap.s-is-navi {
    padding-top: 105px;
  }

  .s-wrap.s-is-gnb {
    padding-top: 60px;
  }

  .s-header {
    position: absolute;
  }

  .footer {
    padding: 50px 0 30px;
  }

  /* text */
  .s-container-title {
    font-size: 1.0625em;
  }

  .s-info-group .s-info-title {
    font-size: 1.0625em;
  }

  .s-info-group .s-info-big-title {
    font-size: 1.08em;
  }

  .s-info-group .s-info-cont {
    font-size: 0.8125em;
  }

  .s-contactus p {
    font-size: 0.6875em;
  }

  .s-legal {
    margin-top: 8px;
  }

  .s-legal p, .s-legal a {
    font-size: 11px;
  }

  .s-tabtitle {
    padding: 11px 0;
    font-size: 1em;
    line-height: 1.5;
  }

  /* img */
  .s-mimg {
    display: none;
  }

  .s-pimg {
    display: block;
  }

  /* button */
  .s-btn-default {
    height: 2.66667em;
    font-size: 1.125em;
    line-height: 2.55556em;
  }

  .zipcode-font {
    font-size: 1em;
  }

  .one_btn_wrapping {
    margin-top: 35px;
  }

  .one_btn_wrapping.formM {
    margin-top: 10px;
  }

  .one_btn_wrapping.formM_t20 {
    margin-top: 20px;
  }

  .form-group > .s-btn-default {
    margin-top: 8px;
  }

  .s-info-box + .one_btn_wrapping {
    margin: 40px auto 0;
  }

  .s-col-btnwrap .s-btn-item {
    padding-left: 2px;
  }

  .s-col-btnwrap .s-btn-item:first-child {
    padding-right: 2px;
  }

  /* icon */
  .s-icon-caution {
    display: block;
    width: 36px;
    height: 29px;
    margin: 0 auto;
    background: url(../images/icon_caution.png) no-repeat 0 0;
    background-size: 36px 29px;
  }

  .s-icon-caution2 {
    display: block;
    width: 49px;
    height: 40px;
    margin: 0 auto;
    background: url(../images/icon_caution2.png) no-repeat 0 0;
  }

  /* form */
  .s-form-title {
    font-size: 1.0625em;
  }

  .form-group .s-form-title + .s-input-inlinegroup .s-input, .form-group .s-form-title + .s-input {
    margin-top: 6px;
  }

  .form-group .s-input, .form-group .s-select, .form-group .s-textarea-box, .form-group .s-radio-tabwrap {
    margin-top: 8px;
  }

  .s-input input {
    height: 50px;
    padding: 12px;
    font-size: 1em;
    box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.25)
  }

  .s-input label {
    top: 1px;
    height: 48px;
    font-size: 1em;
    line-height: 48px;
  }

  .s-select {
    height: 50px;
  }

  .s-select label {
    height: 50px;
    padding: 0 12px;
    font-size: 1em;
    line-height: 48px;
  }

  .s-select select {
    display: none;
  }

  .s-select ul {
    top: 51px;
  }

  .s-select ul button {
    height: 48px;
    font-size: 0.9375em;
  }

  .s-select.s-active ul {
    display: block;
    z-index: 10;
  }

  .s-textarea-box .s-disabledinfo {
    font-size: 1em;
  }

  .s-textarea-box textarea {
    padding: 15px 12px;
    font-size: 1em;
  }

  .s-textarea-box .s-disabledinfo + textarea {
    padding-top: 35px;
  }

  .s-radio-tabwrap .s-radio-tab label {
    padding: 0;
    font-size: 1.0625em;
    line-height: 48px;
  }

  .form-group {
    margin-top: 20px;
  }

  .form-group .s-select + .s-textarea-box {
    margin-top: 8px;
  }

  /* form text */
  .s-form-notice {
    margin-top: 3px;
    font-size: 0.6875em;
  }

  /* form error */
  .s_error_msg {
    margin-top: 5px;
    font-size: 0.8125em;
  }

  /* form dim */
  .s-dimcase-input .s-dimcase {
    padding: 0 12px;
    font-size: 1em;
    line-height: 48px;
  }

  /* tab */
  .s-tab li a {
    position: relative;
    padding: 11px 0;
    font-size: 1em;
    line-height: 1.5em;
  }

  /* panel tab */
  .s-tab.s-type-panel li a {
    height: 70px;
    font-size: 0.875em;
  }

  /* link list */
  .s-des-linklist.s-is-centeradjust .s-des-linkitem .s-des-overview {
    padding: 25px 36px 27px
  }

  .s-des-linkitem .s-item-icon {
    margin-bottom: 7px;
  }

  .s-des-linkitem .s-item-title {
    font-size: 1.0625em;
  }

  .s-des-linkitem .s-item-text {
    margin-top: 4px;
    font-size: 0.8125em;
  }

  /* panel */
  .s-panel-title {
    padding: 5px 18px;
    font-size: 0.8125em;
    line-height: 1.5;
  }

  .s-panel-title span {
    margin-top: 0.3em;
    font-size: 0.923em;
  }

  /* img link panel */
  .s-img-panel li .s-panel-overview {
    padding: 14px 42px 13px 58px;
  }

  .s-img-panel li .s-panel-arrow span {
    right: 10px;
  }

  .s-img-panel li img {
    left: 0;
    width: 46px;
    height: 46px;
    margin-top: -23px;
  }

  .s-img-panel .s-item-title {
    margin-top: 0;
    font-size: 1.0625em;
  }

  .s-img-panel .s-info {
    margin-top: 8px;
    line-height: 16px;
  }

  .s-img-panel .s-info span {
    font-size: 0.75em;
  }

  .s-none-panel {
    padding: 30px 0 25px;
  }

  .s-none-panel p {
    padding-top: 7px;
    font-size: 0.75em;
  }

  .s-none-panel.s-type-all {
    padding-top: 35px;
  }

  .s-none-panel.s-type-all p {
    padding-top: 15px;
  }

  /* accordion */
  .s-img-panel.s-type-accordion li > a:after {
    right: 10px;
  }

  .s-accordion-cont {
    padding: 15px 30px 25px;
  }

  .s-accordion-cont .s-accordion-title {
    font-size: 0.875em;
  }

  .s-accordion-cont .s-accordion-des {
    font-size: 0.8125em;
  }

  .s-accordion-cont .s-btn-wrap {
    margin: 25px 0 0;
  }

  .s-accordion-cont .s-btn-wrap .s-btn-default {
    display: block;
    max-width: 250px;
    margin: 0 auto;
  }

  /* img link panel - type thumb price */
  .s-img-panel.s-type-thumbprice li > a {
    padding-left: 83px;
  }

  .s-img-panel.s-type-thumbprice .s-item-thumbprice {
    left: 0;
    width: 70px;
    height: 46px;
    margin-top: -23px;
    background: url(../images/bg_thumb_price.png) no-repeat 0 0;
    font-size: 0.875em;
    line-height: 46px;
  }

  /* img link panel - type dim thumb price */
  .s-img-panel.s-type-thumbprice .s-dim-thumbprice {
    left: 0;
    width: 70px;
    height: 46px;
    margin-top: -23px;
    background: url(../images/bg_dim_thumb_price.png) no-repeat 0 0;
    font-size: 0.875em;
    line-height: 46px;
  }

  .s-img-panel.s-type-thumbprice .icon-x {
    top: 10px;
    left: 60px;
    width: 14px;
    height: 14px;
    background: url(../images/icon_x.png) no-repeat 0 0;
    background-size: 14px 14px;
    z-index: 1
  }

  /* text panel */
  .s-text-panel .s-panel-overview {
    padding-left: 0;
  }

  .s-text-panel .s-item-title {
    font-size: 1em;
  }

  .s-text-panel.s-type-accordion .s-panel-overview {
    padding: 15px 42px 15px 17px;
  }

  /* board view */
  .s-board-view .s-bv-title {
    padding-bottom: 1em;
    font-size: 1.0625em;
  }

  .s-board-view .s-bv-text {
    font-size: 0.8125em;;
    color: #555;
  }

  /* info box */
  .s-info-box {
    margin-top: 10px;
  }

  .s-info-box .s-info-title {
    font-size: 0.8125em;
  }

  .s-info-box .s-info-text {
    font-size: 0.8125em;
  }

  .s-info-box .s-col-btnwrap {
    margin-top: 15px;
  }

  /* table */
  .tit_payment {
    font-size: 0.900em;
  }

  .s-noline-table3 th, .s-noline-table3 td {
    font-size: 0.750em;
    width: 50%;
  }

  .s-noline-table3 tfoot th, .s-noline-table3 tfoot td {
    font-size: 0.900em;
  }

  .s-phdetail-section .s-noline-table3 {
    width: 200px;
    margin: 0 auto;
  }

  .s-noline-table2 th, .s-noline-table2 td {
    font-size: 0.875em;
  }

  .s-noline-table2 th {
    width: 39%;
  }

  .s-noline-table2 td {
    width: 61%;
  }

  table td .s-info-tiny {
    font-size: 0.85714em;
  }

  /* dropdown style */
  .dropdown .btn-default .s-count {
    margin-top: 0.35em;
  }

  .s-sorting {
    padding: 10px 0;
  }

  .s-sorting .dropdown-menu {
    min-width: 270px;
  }

  .s-benefitinfo .dropdown-menu {
    width: 240px;
  }

  /* bootstrap override */
  .dropdown .btn-default {
    height: auto;
    padding: 2px 27px 2px 12px;
    font-size: 0.8125em;
    line-height: 1.5;
  }

  .dropdown .btn-default .caret {
    right: 12px;
  }

  .dropdown-menu {
    padding: 0 8px;
  }

  .dropdown-menu > li > a {
    padding: 12px 10px;
    font-size: 1em;
  }

  /* header */
  .s-header .s-header-gnb {
    padding: 1.125em 36px 1.0625em;
    background-image: url(../images/bg_gnb.jpg);
  }

  .s-header .s-logo {
    height: auto;
  }

  .s-header .s-logo a img {
    width: 204px;
    height: 25px;
  }

  .s-header .s-util {
    right: 32px;
    top: 1.125em;
  }

  .s-header .s-util .s-log {
    margin-right: 10px;
  }

  .s-navi {
    background-image: url(../images/bg_navi.jpg);
  }

  .s-navi:before, .s-navi:after {
    display: none
  }

  .s-navi ul {
    min-width: 900px;
  }

  .s-navi li {
    padding-left: 40px;
  }

  .s-navi li a {
    padding: 12px 0 11px;
    font-size: 1em;
  }

  /* login */
  .s-sign-container {
    height: 100%;
  }

  .s-sign-container .s-sc-inner {
    height: 70%;
  }

  .welcome_wrapping {
    height: 100%;
  }

  .w_img {
    background-image: url(../images/bg_sign.jpg);
  }

  .welcome {
    position: absolute;
    left: 25%;
    top: 0;
    width: 50%;
    height: 100%;
    padding-top: 0;
  }

  .welcome .sign_info {
    position: absolute;
    left: 0;
    top: 26%;
    width: 100%;
  }

  .welcome .sign_info strong {
    margin-bottom: 2%;
    font-size: 3.4375em;
  }

  .welcome .sign_info span {
    margin-bottom: 0;
    font-size: 1.25em;
  }

  .welcome .btn_wrapping {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 13%;
  }

  .s-scform-inner {
    height: 30%;
  }

  .s-scform-inner .welcome_tv, .s-scform-inner .welcome_tv > div {
    height: 100%;
  }

  .welcome_tv .s-tvcode-section {
    position: absolute;
    left: 0;
    top: 20%;
    width: 100%;
    padding: 0 15px;
  }

  .welcome_tv .s-tvcode-section .s-tvcode-head strong {
    font-size: 1.0625em;
    text-align: left;
  }

  /* payment method */
  .s-pay-slider {
    width: 456px;
    margin: 0 auto;
  }

  .s-des-linklist .s-method-menu {
    max-width: 350px;
    margin: 0 auto;
  }

  .s-pay-slider .s-pay-item .s-card-fullnumber {
    top: 145px;
  }

  .s-pay-slider .s-pay-item .s-card-text {
    font-size: 1.25em;
  }

  .s-pay-slider .s-pay-item .s-card-text-icon {
    font-size: 1.8em;
  }

  .s-pay-slider .slick-arrow {
    top: 184px;
  }

  .s-recentorder-list {
    height: 234px
  }

  /* purchase history detail */
  .s-phdetail-section {
    padding: 40px 0;
  }

  .s-app-view img {
    width: 120px;
    margin-bottom: 25px;
  }

  .s-app-view .s-app-title {
    font-size: 1.4375em;
  }

  .s-app-view .s-app-subtitle {
    font-size: 0.875em;
  }

  .s-phdetail-section .s-noline-table2 {
    width: 410px;
    margin: 0 auto;
  }

  .s-phdetail-section .s-contactus {
    margin-left: 0;
    margin-right: 0;
  }

  .s-phdetail-section .s-btn {
    display: block;
    max-width: 250px;
    margin: 20px auto 0;
  }

  /* promotional codes */
  .s-addcode-section {
    padding-top: 35px;
    padding-bottom: 35px;
    background-image: url(../images/bg_codes.jpg);
  }

  .s-addcode-section .s-form-notice {
    font-size: 0.750em;
    margin-bottom: 0.4em;
  }

  .s-addcode-section .col-md-6 {
    padding-left: 63px;
    padding-right: 64px;
  }

  /* add method */
  .s-addmethod-section {
    padding-top: 40px;
  }

  .s_tel_code {
    padding-top: 40px;
  }

  .s_tel_code .authentication_code {
    margin-bottom: 38px;
  }

  .s_tel_code .authentication_code dt {
    padding-bottom: 18px;
    background-image: url(../images/bg_card_tel.png);
    font-size: 1.0625em;
  }

  .s_tel_code .authentication_code .code_n {
    padding: 12px 0;
    font-size: 4.125em;
  }

  .s_tel_code .authentication_code .expires {
    font-size: 1em;
  }

  .s_tel_code .info {
    margin-bottom: 45px;
    font-size: 0.875em;
  }

  .completed_add_token .msg {
    padding-top: 30px;
    margin-bottom: 40px;
  }

  .completed_add_token .msg strong {
    font-size: 1.075em;
    margin-bottom: 0.25em;
  }

  .completed_add_token .msg p {
    padding-top: 16em;
    background-image: url(../images/bg_completed_paymethod.png);
    background-size: 230px auto;
    background-position: 50% 5px;
    font-size: 1.02em;
    padding-left: 0;
    padding-right: 0;
  }

  .completed_add_token .msg_continue {
    margin-bottom: 20px;
    font-size: 0.875em;
  }

  .completed_payment .msg {
    margin-top: 0;
  }

  .completed_payment .msg strong {
    font-size: 1.075em;
    margin-bottom: 0.25em;
  }

  .completed_payment .msg p {
    padding-top: 16em;
    background-image: url(../images/bg_completed_paymethod.png);
    background-size: 230px auto;
    background-position: 50% 5px;
    font-size: 1.02em;
    padding-left: 0;
    padding-right: 0;
  }

  .completed_payment .msg_continue {
    margin-bottom: 20px;
    font-size: 0.875em;
  }

  /* cancellation guide */
  .s-cancellation-section {
    padding-top: 36px;
  }

  .s-cancellation-title {
    font-size: 1.375em;
    padding: 0;
  }

  .s-cancellation-title br {
    display: none;
  }

  .pc_none {
    display: none;
  }

  .mob_none {
    display: block;
  }

  .s-list-title {
    font-size: 1.000em;
    padding-top: 9px;
    padding-bottom: 0.889em;
  }

  .s-cancellation-section .s-topline-section {
    margin-top: 1.250em;
    padding-bottom: 0.1875em;
  }

  .s-list-item li {
    font-size: 0.813em;
    line-height: 190%;
  }

  .s-list-info {
    font-size: 0.750em;
    padding-top: 1.111em;
  }

  .list-group {
    padding: 0;
  }

  .list-group:last-child {
    padding-top: 18px;
  }

  .list-group:last-child .s-list-title {
    padding-bottom: 0.444em
  }

  /* error */
  .s-error-section {
    padding-top: 52px;
  }

  .s-error-section .s-error-message {
    margin-top: 25px;
    font-size: 1.0625em;
    line-height: 150%;
  }

  .s-error-section .one_btn_wrapping {
    margin-top: 45px;
  }

  /* remodal */
  .remodal-contents {
    padding: 20px 0;
  }

  .remodal-textarea, .remodal-contarea {
    margin: 0 20px;
  }

  .remodal-textarea.remodal-textarea-t, .remodal-contarea.remodal-contarea-t {
    font-size: 0.8125em;
  }

  .remodal-textarea.remodal-textarea-t .remodal-title, .remodal-contarea.remodal-contarea-t .remodal-title {
    font-size: 1.15385em;
  }

  .remodal-contents.remodal-type-2line, .remodal-contents.remodal-type-4line {
    width: 353px;
  }

  .remodal-contents.remodal-type-longline {
    width: 457px;
  }

  .remodal-contents.remodal-type-withlistbox {
    width: 475px;
  }

  .remodal-imgarea {
    margin: 0 50px;
  }

  .remodal-text {
    font-size: 1.231em;
  }

  .remodal-type-toast {
    width: 337px;
    padding: 12px 37px 11px;
  }

  .remodal-type-toast .remodal-text {
    font-size: 1em;
    line-height: 23px;
  }

  .remodal-link-bottom {
    margin-top: 12px;
  }

  .remodal-btnarea {
    padding: 20px 46px 0;
  }

  .remodal-contents.remodal-type-longline .remodal-btnarea {
    padding: 20px 99px 0;
  }

  .remodal-contents.remodal-type-withlistbox .remodal-btnarea {
    padding: 20px 99px 0;
  }

  .remodal-contents .remodal-btnarea.s-col-btnwrap {
    padding: 20px 20px 0;
  }

  .remodal-slider-wrap {
    padding: 0 20px;
  }

  .remodal-slider-wrap .slick-arrow {
    top: 66px;
  }

  .remodal-slider-wrap .slick-prev {
    left: 12px;
  }

  .remodal-slider-wrap .slick-next {
    right: 12px;
  }

  .remodal-slider-wrap .slick-dots {
    bottom: 55px;
  }

  .remodal-slider-wrap .remodal-contarea .remodal-title, .remodal-slider-wrap .remodal-contarea .remodal-text, .remodal-slider-wrap .remodal-contarea .remodal-link-bottom {
    padding: 0 20px;
  }

  .remodal-slider-wrap .remodal-contarea .remodal-imgarea {
    margin: 0 48px;
    padding: 0;
  }

  .remodal-slider-wrap .remodal-btnarea {
    margin-top: 15px;
  }

  /* page loading spinner */
  .s-loading-spinner {
    width: 57px;
    height: 57px;
    margin: -29px 0 0 -29px;
    background-image: url(../images/loading/loading_blacks_00.png);
    -webkit-animation-name: LoadingSpinner;
    animation-name: LoadingSpinner;
  }

  @keyframes LoadingSpinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes LoadingSpinner {
    0% {
      background-image: url(../images/loading/loading_blacks_00.png);
    }
    3% {
      background-image: url(../images/loading/loading_blacks_01.png);
    }
    6% {
      background-image: url(../images/loading/loading_blacks_02.png);
    }
    9% {
      background-image: url(../images/loading/loading_blacks_03.png);
    }
    11% {
      background-image: url(../images/loading/loading_blacks_04.png);
    }
    13% {
      background-image: url(../images/loading/loading_blacks_05.png);
    }
    16% {
      background-image: url(../images/loading/loading_blacks_06.png);
    }
    19% {
      background-image: url(../images/loading/loading_blacks_07.png);
    }
    21% {
      background-image: url(../images/loading/loading_blacks_08.png);
    }
    25% {
      background-image: url(../images/loading/loading_blacks_09.png);
    }
    29% {
      background-image: url(../images/loading/loading_blacks_10.png);
    }
    31% {
      background-image: url(../images/loading/loading_blacks_11.png);
    }
    34% {
      background-image: url(../images/loading/loading_blacks_12.png);
    }
    37% {
      background-image: url(../images/loading/loading_blacks_13.png);
    }
    40% {
      background-image: url(../images/loading/loading_blacks_14.png);
    }
    43% {
      background-image: url(../images/loading/loading_blacks_15.png);
    }
    47% {
      background-image: url(../images/loading/loading_blacks_16.png);
    }
    50% {
      background-image: url(../images/loading/loading_blacks_17.png);
    }
    53% {
      background-image: url(../images/loading/loading_blacks_18.png);
    }
    56% {
      background-image: url(../images/loading/loading_blacks_19.png);
    }
    59% {
      background-image: url(../images/loading/loading_blacks_20.png);
    }
    61% {
      background-image: url(../images/loading/loading_blacks_21.png);
    }
    63% {
      background-image: url(../images/loading/loading_blacks_22.png);
    }
    69% {
      background-image: url(../images/loading/loading_blacks_23.png);
    }
    72% {
      background-image: url(../images/loading/loading_blacks_24.png);
    }
    75% {
      background-image: url(../images/loading/loading_blacks_25.png);
    }
    77% {
      background-image: url(../images/loading/loading_blacks_26.png);
    }
    80% {
      background-image: url(../images/loading/loading_blacks_27.png);
    }
    83% {
      background-image: url(../images/loading/loading_blacks_28.png);
    }
    85% {
      background-image: url(../images/loading/loading_blacks_29.png);
    }
    89% {
      background-image: url(../images/loading/loading_blacks_30.png);
    }
    91% {
      background-image: url(../images/loading/loading_blacks_31.png);
    }
    93% {
      background-image: url(../images/loading/loading_blacks_32.png);
    }
    95% {
      background-image: url(../images/loading/loading_blacks_33.png);
    }
    97% {
      background-image: url(../images/loading/loading_blacks_34.png);
    }
    100% {
      background-image: url(../images/loading/loading_blacks_35.png);
    }
  }


  /* form loading spinner */
  .form_spinner {
    position: absolute;
    right: 11px;
    top: 50%;
    margin-top: -11px;
    width: 22px;
    height: 22px;
    background-image: url(../images/loading/loading_blacksxs_00.png);
    -webkit-animation-name: Spinner;
    animation-name: Spinner;
  }

  .card_spinner {
    position: absolute;
    right: 200px;
    top: 50%;
    margin-top: -27px;
    width: 57px;
    height: 57px;
    background-image: url(../images/loading/loading_whites_00.png);
    -webkit-animation-name: Spinner;
    animation-name: Spinner;
  }

  @keyframes Spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes Spinner {
    0% {
      background-image: url(../images/loading/loading_blacksxs_00.png);
    }
    3% {
      background-image: url(../images/loading/loading_blacksxs_01.png);
    }
    6% {
      background-image: url(../images/loading/loading_blacksxs_02.png);
    }
    9% {
      background-image: url(../images/loading/loading_blacksxs_03.png);
    }
    11% {
      background-image: url(../images/loading/loading_blacksxs_04.png);
    }
    13% {
      background-image: url(../images/loading/loading_blacksxs_05.png);
    }
    16% {
      background-image: url(../images/loading/loading_blacksxs_06.png);
    }
    19% {
      background-image: url(../images/loading/loading_blacksxs_07.png);
    }
    21% {
      background-image: url(../images/loading/loading_blacksxs_08.png);
    }
    25% {
      background-image: url(../images/loading/loading_blacksxs_09.png);
    }
    29% {
      background-image: url(../images/loading/loading_blacksxs_10.png);
    }
    31% {
      background-image: url(../images/loading/loading_blacksxs_11.png);
    }
    34% {
      background-image: url(../images/loading/loading_blacksxs_12.png);
    }
    37% {
      background-image: url(../images/loading/loading_blacksxs_13.png);
    }
    40% {
      background-image: url(../images/loading/loading_blacksxs_14.png);
    }
    43% {
      background-image: url(../images/loading/loading_blacksxs_15.png);
    }
    47% {
      background-image: url(../images/loading/loading_blacksxs_16.png);
    }
    50% {
      background-image: url(../images/loading/loading_blacksxs_17.png);
    }
    53% {
      background-image: url(../images/loading/loading_blacksxs_18.png);
    }
    56% {
      background-image: url(../images/loading/loading_blacksxs_19.png);
    }
    59% {
      background-image: url(../images/loading/loading_blacksxs_20.png);
    }
    61% {
      background-image: url(../images/loading/loading_blacksxs_21.png);
    }
    63% {
      background-image: url(../images/loading/loading_blacksxs_22.png);
    }
    69% {
      background-image: url(../images/loading/loading_blacksxs_23.png);
    }
    72% {
      background-image: url(../images/loading/loading_blacksxs_24.png);
    }
    75% {
      background-image: url(../images/loading/loading_blacksxs_25.png);
    }
    77% {
      background-image: url(../images/loading/loading_blacksxs_26.png);
    }
    80% {
      background-image: url(../images/loading/loading_blacksxs_27.png);
    }
    83% {
      background-image: url(../images/loading/loading_blacksxs_28.png);
    }
    85% {
      background-image: url(../images/loading/loading_blacksxs_29.png);
    }
    89% {
      background-image: url(../images/loading/loading_blacksxs_30.png);
    }
    91% {
      background-image: url(../images/loading/loading_blacksxs_31.png);
    }
    93% {
      background-image: url(../images/loading/loading_blacksxs_32.png);
    }
    95% {
      background-image: url(../images/loading/loading_blacksxs_33.png);
    }
    97% {
      background-image: url(../images/loading/loading_blacksxs_34.png);
    }
    100% {
      background-image: url(../images/loading/loading_blacksxs_35.png);
    }

  }
}

@media (min-width: 359px) and (max-width: 992px) {
  html, body {
    font-size: 18px;
  }

}

/* ***** 2016.11.01 추가 ***** */

/* disable */
.disable, .disable:hover, .disable:active {
  cursor: default;
  color: #c0c0c0;
}

a {
  cursor: pointer;
}

/* ***** 2018.07.19 Added, 2020.11.03 Modified (yyh) ***** */

html, body {
  word-break: break-all
}

/*
 * Disable orange outline highlight on focus
 * Link : https://stackoverflow.com/questions/5210481/disable-orange-outline-highlight-on-focus
 */
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-focus-ring-color: rgba(255, 255, 255, 0);
  outline: none;
}

/* ***** 2020.04.21 추가 (yyh) ***** */

.popup-with-listbox::-webkit-scrollbar {
  width: 3px;
}

.popup-with-listbox::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

.popup-with-listbox::-webkit-scrollbar-thumb {
  background: rgba(1, 1, 1, 1);
}

.popup-with-listbox {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 105px;
  text-align: left;
  margin: 20px 0 0 0;
  background-color: rgba(0, 0, 0, 0.05);
}

.padding-top-20 {
  padding-top: 20px;
}

/* ***** 2020.11.02 추가 (yyh) ***** */

.s-info-underline {
  position: relative;
  height: 120px;
}

.s-info-underline:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 15px;
  background: url('../images/bg_divider.png') no-repeat;
  width: 100%;
  height: 3px;
  background-size: 100%;
}

@media (min-width: 992px) {
  .s-info-underline::after {
    left: -30px;
    bottom: 0;
    width: 460px;
  }
}

.padding-top-3em {
  padding-top: 3em;
}

.s-pay-page-row-padding-margin {
  padding: 15px 35px 0;
}

@media (min-width: 992px) {
  .s-pay-page-row-padding-margin {
    margin-top: -30px;
    padding: 0;
  }
}

.s-pay-page-info-cont {
  padding-top: 0.5em;
  color: #555555;
}

@media (min-width: 992px) {
  .s-pay-page-info-cont {
    font-size: 1.1em;
  }
}

.s-pay-page-info-title-color {
  color: #6a8993;
}

.s-icon-card {
  padding-left: 30px;
}

.s-info-card::before {
  content: '';
  display: inline-block;
  background: url('../images/icon_card.png') no-repeat;
  width: 22px;
  height: 12px;
}

.s-sca-popup-outer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30; /* Should be : s-dim < s-sca-popup-outer < remodal-overlay (9999) */
}

.s-sca-popup-inner {
  display: block;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  width: 390px;
  height: 400px;
  border: 0;
  background-color: rgb(222, 226, 228);
  color: rgba(0, 0, 0, 1);
}

.s-sca-popup-inner-title-bar {
  background-color: rgb(37, 70, 80);
  margin: auto;
  padding: 12px;
}

.s-sca-popup-inner-title-bar-close {
  color: #aaaaaa;
  line-height: 0;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.s-sca-popup-inner-title-bar-close:hover, .s-sca-popup-inner-title-bar-close:focus {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}

.s-sca-iframe {
  height: 100%;
  width: 100%;
  border: 0;
}

.s-ellipsis-text-overflow {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}


.s-vaultcard-thumbnail {
  width: 70px;
  display: inline-block;
  margin-right: 10px;
}

.q-title-text {
  font-size: 0.75em;
  font-weight: 400;
  color: #555;
  line-height: 1.8;
  margin: 1em 0 2.5em
}

.q-txt-left {
  text-align: left
}

.q-pl0 {
  padding-left: 0
}

.q-regs-card .s-des-overview {
  padding: 1.3em 56px 1.3em 20px
}

.q-regs-card .s-des-arrow {
  width: 56px
}

.q-divider {
  position: relative;
  margin: 25px 0;
  height: 1px
}

.q-div-transparent:before {
  content: "";
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
  bottom: 0;
  background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, .2), transparent);
}

.s-vaultcard-terms-link {
  font-weight: 500;
  text-decoration: underline;
}

#scaAdyen{
  height: 100%;
}

.adyen-checkout__threeds2__challenge {
  height: 100%;
  width: 100%;
}

